import { Link } from 'gatsby'
import React from 'react'
import { format, parseISO } from 'date-fns'
import {buildImageObj, getDefaultPixelRatio} from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import * as styles from './project-preview.module.css'
import { PropTypes } from 'prop-types'

function ProjectPreview (props) {
  const {mainImage, publishedAt, title, slug} = props
  return (
    <Link className={styles.root} to={`/project/${slug.current}`}>
      <div className={styles.item}>

        <div className={styles.leadMediaThumb}>
          {mainImage && mainImage.asset && (
            <img
              src={imageUrlFor(buildImageObj(mainImage)).width(383)
                .height(265).fit('crop').dpr(getDefaultPixelRatio())
                .url()}
              alt={mainImage.alt}
            />
          )}
        </div>

        <div className={styles.content}>
          {publishedAt && (
            <div className={styles.publishedAt}>
              {format(parseISO(publishedAt), 'dd. MMMM yyyy')}
            </div>
          )}
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.readmore}>Read more →</p>
        </div>
      </div>
    </Link>
  )
}

ProjectPreview.propTypes = {
  title: PropTypes.string,
  publishedAt: PropTypes.string,
  mainImage: PropTypes.shape({
    alt: PropTypes.string,
    asset:  PropTypes.shape({
      _id: PropTypes.string,
    })
  }),
  slug: PropTypes.shape({
    current: PropTypes.string,
  })
}

export default ProjectPreview
