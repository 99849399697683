import React, { useState } from 'react'
import { ContactModal } from './ContactModal'
import * as styles from './contact-demo.module.css'
import { PropTypes } from 'prop-types'

function ContactDemo({ title, subtitle, color, isMini }) {
  const [showContactModal, setShowContactModal] = useState(false)
  return (

    <div className={isMini? styles.miniRoot : styles.root}>
      {!isMini && <div>
        <p>Få en kostnadsfri demonstration</p>
        <h2 className={styles.headline}>{title}</h2>
      </div>}

      {isMini &&
      <div className={styles.miniText}>Vill du ha en demo av vår lösning? </div>}
      <button
        onClick={() => setShowContactModal(true)}
        className={isMini ? styles.miniButton : styles.button}>
        {subtitle}
      </button>
      {showContactModal && (
        <ContactModal onClose={() => setShowContactModal(false)} />
      )}
    </div>


  )
}
ContactDemo.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default ContactDemo
