// extracted by mini-css-extract-plugin
export var box404 = "pages-module--box404--YujCS";
export var button404 = "pages-module--button404--r7V+w";
export var buttonAlign = "pages-module--buttonAlign--kOENl";
export var centered = "pages-module--centered--7NeVk";
export var grid2columns = "pages-module--grid2columns--XVnNx";
export var grid321columns = "pages-module--grid321columns--WacXY";
export var grid3columns = "pages-module--grid3columns--RstRd";
export var mediaCard = "pages-module--mediaCard--XxfZM";
export var sellingPoints = "pages-module--sellingPoints--tvTp1";
export var separator = "pages-module--separator--tBYVK";
export var subTitle = "pages-module--subTitle--4+DE-";
export var subTitleResources = "pages-module--subTitleResources--3L8Ah";
export var subtitle404 = "pages-module--subtitle404--TJUOr";
export var title404 = "pages-module--title404--L-FwN";
export var videoCard = "pages-module--videoCard--oo3qM";
export var whiteBox = "pages-module--whiteBox--YzV42";