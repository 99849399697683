import React, { useState } from 'react'
import { ContactModal } from './ContactModal'
import * as styles from './contact-demo.module.css'
import { Button } from '../Button/Button'


const ContactDemoButton = ({ color, left }) => {

  const [showContactModal, setShowContactModal] = useState(false)
  return (
    <div>
      <div className={left ? '': styles.demoButton}>
        <Button
          onClick={() => setShowContactModal(true)}
          buttonText='Boka en demo'
          color={color}
        />
      </div>
      {showContactModal && (
        <ContactModal onClose={() => setShowContactModal(false)} />
      )}
    </div>
  )
}

export default ContactDemoButton