
import React from 'react'
import Container from './container'
import ContactDemo from './contact/contact-demo'


export const DemoPitch = () => {
  return (
    <Container>
      <ContactDemo 
      title={'Läs mer om hur vår lösning kan användas för att följa upp ett antal patientgrupper och bidra till en mer hållbar vård.'}
      subtitle={'Boka en demo'}
     />
    </Container>
  )
}